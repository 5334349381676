<template>
  <div>
    <div v-if="!loadingPage" style="display:flex;justify-content:center;min-height:100vh;width:100vw;"  class="landing-wrapper" :class="{ fullscreen: landing && landing.sections.styling.Fullscreen }">
      <div v-if="!landing" class="text-center" style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
        <h1 class="text-danger">404</h1>
        <h4>Page not found</h4>
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingPage: false,
      landing: null,
      token: this.$route.params.token,
    }
  },
  mounted() {
    this.getPage()
    document.body.style.paddingBottom = 0
  },

  methods: {
    getPage() {
      this.loadingPage = true

      this.$store.dispatch('landing/findByToken', this.$route.params.token)
        .then(landing => {
          this.loadingPage = false
          localStorage.setItem('referrer', this.$route.params.token)
          this.$router.push({ name: 'landing', params: { id: landing.link, business: landing.business_id } })
        })
        .catch((e) => {
          this.loadingPage = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}
.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.input-group span {
  width: 100%;
}

.landing-widget {
  .main-container {
    width: 100% !important;
  }
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.legal-container {
  .privacy-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline!important;
    }
  } ;
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
